import i18n from 'i18next';
import { initReactI18next  } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import tw from './assets/i18n/zh-TW.json';
import en from './assets/i18n/en.json';
import ch from './assets/i18n/zh-CH.json';

const resources = {
    'en': { translation: en },
    'zh': { translation: ch },
    'zh-CH': { translation: ch },
    'zh-TW': { translation: tw },
}
const detection = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage'],
    // caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    // cookieMinutes: 10,
    // cookieDomain: window.location.host,

    // optional htmlTag with lang attribute, the default is:
    // htmlTag: document.documentElement,

    // optional set cookie options
    // cookieOptions: { path: '/', sameSite: 'strict' }
}

export default i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection,
        fallbackLng: 'en',
        debug: false,
        keySeparator: false,
        supportedLngs: [ 'en', 'zh', 'zh-TW', 'zh-CH' ],
        interpolation: {
            escapeValue: false
        }
    });
