import React from "react";
const Root = React.lazy(() => import("./pages/Home/IndexRoot"));
const Contact = React.lazy(() => import("./pages/Contact/Index"));
const Community = React.lazy(() => import("./pages/Community/Index"));
const GooglePolicy = React.lazy(() => import("./pages/GooglePolicy/Index"));
const routes = [
    { path: "/", component: Root, isWithoutLayout: true, exact: true },
    { path: "/contact", component: Contact, isWithoutLayout: false, exact: true },
    { path: "/community", component: Community, isWithoutLayout: false, exact: true },
    { path: "/google-api-limited-use-disclosure", component: GooglePolicy, isWithoutLayout: false, exact: true },
]

export default routes;