import React, { Component } from "react";
import {isAndroid, isIOS} from "react-device-detect";

const youtubeVideoLink = "www.youtube.com/watch?v=u8NGR4V1lE0";
class Index extends Component {
    constructor(props){
        super(props);
        this.state = {}
        this.androidApp = `intent://${youtubeVideoLink}#Intent;package=com.google.android.youtube;scheme=https;end`;
        this.iosApp = `vnd.youtube://${youtubeVideoLink}`;
    }
    componentDidMount() {
        const { iosApp, androidApp } = this;
        const youtubeVideoUrl = `https://${youtubeVideoLink}`;
        if(isIOS) {
            window.location.href = iosApp;
            window.setTimeout(() => {
                window.location.href = youtubeVideoUrl;
            }, 25);
        } else if (isAndroid) {
            window.location.href = androidApp;
            window.setTimeout(() => {
                window.location.href = youtubeVideoUrl;
            }, 25)
        } else {
            window.location.href = youtubeVideoUrl;
        }
        window.addEventListener('pagehide', this.killPopup);
    }

    componentWillUnmount() {
        window.removeEventListener('pagehide', this.killPopup)
    }

    killPopup = () => {
        window.removeEventListener('pagehide', this.killPopup);
    }

    render() {
        return <></>
    }
}
export default Index;