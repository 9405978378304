import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

const Topbar = React.lazy(() => import('./Topbar'));
const Footer = React.lazy(() => import('../pages/Home/Footer'));

class Layout extends Component {
    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"/>
                        <div className="double-bounce2"/>
                    </div>
                </div>
            </div>
        );
    };

    render = () => {
        return (
            <React.Fragment>
                <Suspense fallback={this.Loader()}>
                    <Topbar/>
                    {this.props.children}
                    {(() => {
                        if(this.props.location.pathname === '/contact' || this.props.location.pathname.startsWith('/google-api-limited-use-disclosure')) {
                            return <Footer/>
                        }
                    })()}
                </Suspense>
            </React.Fragment>
        );
    }
}
export default withRouter(Layout);