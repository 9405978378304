import React, { Component, Suspense } from "react";
import Layout from './Layout/';
import {
    Route,
    Switch,
    BrowserRouter as Router,
    withRouter,
} from "react-router-dom";

import "./App.scss";
import "./assets/css/colors/default.css";
import "./assets/css/demo.css";
import "./assets/css/wcc.css";

import routes from "./routes";
import Media from "./pages/Media/Index";

function withLayout(WrappedComponent) {
    return class extends Component {
        render() {
            return <Layout><WrappedComponent></WrappedComponent></Layout>
        }
    }
}

class App extends Component {
    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"/>
                        <div className="double-bounce2"/>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        const { NODE_ENV } = process.env;
        console.log(NODE_ENV === 'development' ? 'This is development mode.' : 'This is production mode.');
        return (
            <React.Fragment>
                <Router>
                    <Suspense fallback={this.Loader()}>
                        <Switch>
                            {routes.map((route, key) =>
                                route.isWithoutLayout
                                ? (<Route path={route.path} exact={route.exact} component={route.component} key={key} loc={route.loc}/>)
                                : (<Route path={route.path} exact={route.exact} component={withLayout(route.component)} key={key}/>)
                            )}
                            <Route path="/media" component={Media}/>
                        </Switch>
                    </Suspense>
                </Router>
            </React.Fragment>
        )
    }
}

export default withRouter(App);
